import config from '@/services/config'
import axios from 'axios'
import {tokenExpired} from '@/auth/utils'

export default {
  getLeads() {
    return axios.get(`${config.urlCore}/api/v3/leads/`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  getStatusPayment(stat) {
    let status = '';
    switch (stat) {
    case 'pending_payment':
      status = 'Pendiente';
      break;
    case 'approved_payment':
      status = 'Aprobado';
      break
    }
    return status;
  },
  getPaymentType(stat) {
    let status = '';
    switch (stat) {
    case 'transfer_payment_lead':
      status = 'Transferencia';
      break;
    case 'paypal_payment_lead':
      status = 'Paypal';
      break
    case 'not_defined_payment_lead':
      status = 'Pendiente';
      break
    }
    return status;
  },
  getDetail(slug_name) {
    return axios.get(`${config.urlCore}/api/v3/leads/${slug_name}/`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  }
}
