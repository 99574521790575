<template>
  <component :is="tag" v-on="$listeners">
    <div class="d-flex align-items-center pb-1" :class="user.type === 'null' ? 'without-badge' : ''">
      <b-avatar :src="user.avatar_url" badge-variant="ligth" size="42">
        <template #badge>
          <i v-if="user.type !== 'null'" :class="`${utils.getClassNetworkSpan(user.type)} border-icon`" />
        </template>
      </b-avatar>
      <div class="chat-info flex-grow-1">
        <h5 class="mb-0 pl-1">
          {{ user.title }}
          <image-verified
            v-if="user.is_verified"
          />
        </h5>
        <p class="card-text text-truncate pl-1">
          {{ user.username }}
        </p>
      </div>
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge, BIcon } from 'bootstrap-vue';
import { formatDateToMonthShort } from '@core/utils/filter';
import utils from '@/libs/utils';
import useChat from './useChat';
import ImageVerified from '@/views/components/image/ImageVerified.vue';

export default {
  components: {
    ImageVerified,
    BAvatar,
    BBadge,
    BIcon,
  },
  data() {
    return {
      utils,
    };
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    user: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      dedfault: false,
    },
  },
  setup() {
    const { resolveAvatarBadgeVariant } = useChat();
    return { formatDateToMonthShort, resolveAvatarBadgeVariant };
  },
};
</script>

<style>
.border-icon {
  padding: 2px !important;
  font-size: 1.2em !important;
  margin-right: -0.4em !important;
  background: white;
  border-radius: 50%;
}
.without-badge > .b-avatar {
  background-color: #fff
}
</style>
