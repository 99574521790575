import config from '@/services/config'
import axios from 'axios'
import {tokenExpired} from '@/auth/utils'

export default {
  getResults (datos) {
    return axios.post(`${config.urlPhp}/api/v1/buscador/influencers`, datos, {
      headers: {
        'Authorization': 'crI4XDnr.wbU0kzCV1uVxv6EXDkxI2HupRuEgjlqu',
      }
    })
      .then(response => response.data)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  saveLogSearch (datos) {
    return axios.post(`${config.urlCore}/api/v1/finder/log/search/`, datos, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    })
      .then(response => response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  getFirstPage (datos) {
    return axios.post(`${config.urlCore}/api/v1/finder/searcher/`, datos, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  analize (name, net = '') {
    const url = `${config.urlCore}/api/v1/finder/suggester/`
    return axios.post(url,
      {
        query: name,
        networks: net
      },
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })

  },
  getLocation (loc) {
    return axios.get(`${config.urlBase}/search-location`,
      {
        params:{
          query: loc
        }
      })
      .then(response => response.data.data)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getCategories(network) {
    return axios.get(`${config.urlCore}/api/v1/finder/get/taxonomy/${network}/`,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getInfluencerIn(username, network, objecttype) {
    return axios.get(`${config.urlCore}/api/v1/finder/searcher/influencer-in/${username}/${network}/${objecttype}/`,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      });
  }
}
