<template>
  <b-row class="match-height">

    <b-col  class="container-card-creator" md="6" xl="3" lg="3" sm="6">
        
      <card-Creator></card-Creator>  
    </b-col>

    <b-col md="6" xl="6" lg="6" sm="6">
      <b-card>
        <b-link class="link-my-work" @click="$router.push({name: 'my_work'})">{{ $t("dashboard.myWork") }}</b-link>
       <b-row>
        <b-tabs content-class="p-0">
          <b-tab :title="$t('dashboard.desk')"> <desk></desk></b-tab>
          <b-tab :title="$t('dashboard.proposals')" disabled></b-tab>
          <b-tab :title="$t('dashboard.pitchesCampaigns')" disabled></b-tab>
          <b-tab :title="$t('dashboard.transactions')" disabled></b-tab>
          <b-tab :title="$t('dashboard.myNetworks')" @click="$router.push({name: 'my_work', params: { section: 'networks' } })"></b-tab>
        </b-tabs>
        </b-row>
      </b-card>
    </b-col>
    
    <b-col md="6" xl="3" lg="3" sm="6">
      <b-button 
        class="button-proposal" 
        @click="$router.push({name: 'step_category'})"
        :disabled="!utils.getUserData().is_superuser"
        >{{ $t("dashboard.createProposal") }}
      </b-button>

      <b-card class="card-apply-job mt-1" footer-class="without-border">

        <!-- payment  -->
        <div class="apply-job-package bg-light-primary rounded">
          <div>
            <h2 class="d-inline mr-25">
              {{ total_balance }}
            </h2>
            <sub class="text-body"
              ><small>/ {{ $t("dashboard.balance") }}</small></sub
            >
          </div>
          <b-badge variant="light-primary" pill>
            {{ $t("dashboard.balanceReady") }}
          </b-badge>
        </div>
        <!--/ payment  -->
        <h4 class="apply-job-title mt-2 mb-1">
          {{ $t("dashboard.titleBalance") }}
        </h4>
        <b-card-text class="mt-2" v-html="$t('dashboard.textBalance')">
        </b-card-text>

        <template #footer>
          <b-button
            block
            variant="outline-primary"
            @click="triggerChat"
          >
            {{ $t("dashboard.contactSupport") }}
          </b-button>
        </template>
      </b-card>
    </b-col>
    

      <b-col cols="12" class="mt-5" v-if="!groupRol">
        <b-card class="banner-leads">
          <feather-icon icon="CreditCardIcon" size="40" class="icon-leads"/>
          <h1 class="ml-2">{{ $t("dashboard.selectTool") }}</h1>
        </b-card>
      </b-col>

    <b-row  class="match-height" v-if="!groupRol">
      <b-col
        cols="12"
        class="col-lg-4 col-md-6 col-sm-12"
        v-for="(cardInfo, index) in cardsCreator"
        :key="index"
      >
        <b-link
          :href="cardInfo.href"
          target="_blank"
          :class="
            cardInfo.status === 'inactive'
              ? 'link-disabled all-link'
              : 'all-link'
          "
          :disabled="cardInfo.status !== 'active'"
        >
          <b-card
            v-if="cardInfo.img"
            class="hoverCard"
            header-class="header-card-creator"
            :img-src="cardInfo.img"
            :img-alt="cardInfo.title"
            img-top
          >
            <b-card-title class="mt-2 title-card"
              >{{ $t(cardInfo.title) }}
              <b-badge
                v-if="cardInfo.alert"
                :id="'tooltip-creator-' + cardInfo.id"
                variant="warning"
              >
                <feather-icon icon="InfoIcon" size="10" /> </b-badge
            ></b-card-title>
            <b-card-text class="text-black">
              {{ $t(cardInfo.description) }}
            </b-card-text>
          </b-card>
          <b-card
            v-else
            :header-bg-variant="getVariantBg(cardInfo)"
            header=" "
            header-class="header-card-creator"
          >
            <b-card-title class="mt-2 title-card">{{
              $t(cardInfo.title)
            }}</b-card-title>
            <b-card-text class="black">
              {{ $t(cardInfo.description) }}
            </b-card-text>
          </b-card>
        </b-link>
        <b-tooltip
          v-if="cardInfo.alert"
          :target="'tooltip-creator-' + cardInfo.id"
          triggers="hover"
          placement="right"
        >
          {{ $t(cardInfo.alert_text) }}
        </b-tooltip>
      </b-col>
    </b-row>

    <div v-if="groupRol === 'creator'" class="col-12">
      <home-banner
        :imageName="homeBanner.imageName"
        :title="homeBanner.title"
        :textContent="homeBanner.textContent"
        :buttonName="homeBanner.buttonName"
        :link="homeBanner.link"
      ></home-banner>
    </div>
  </b-row>
  
</template>
    
<script>
import {
  BTab,
  BTabs,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BCardTitle,
  BBadge,
  BTooltip,
  BButton,
} from "bootstrap-vue";
import "swiper/css/swiper.css";
import utils from "@/libs/utils";
import HomeBanner from "@/views/components/banner/HomeBanner.vue"
import service_leads from '@/services/leads'
import utils_leads from '@/libs/leads'
import service from "@/services/dashboard";
import CardCreator from '@/views/dashboard/analytics/CardCreator.vue'
import desk from '@/views/pages/creator/desk.vue';


export default {
  name: "DashboardCreator",
  components: {
    desk,
    BTab,
    BTabs,
    CardCreator,
    HomeBanner,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BCardTitle,
    BBadge,
    BTooltip,
    BButton,
  },
  data() {
    return {
      total_balance: 0,
      data: {},
      service_leads,
      participation_lead: null,
      utils_leads,
      datasuggest: [],
      filteredOptions: [],
      inputProps: {
        id: "autosuggest__input",
        class: "form-control",
        placeholder: 'Type "e"',
      },
      cardsCreator: [
        {
          id: 1,
          title: "dashboard.crowdmissions",
          img: require("@/assets/images/dashboard/creator/crowdmissions.png"),
          description: "dashboard.descriptionCrowdmissions",
          href: null,
          variant: "primary",
          alert: "warning",
          alert_text: "dashboard.alertCrowdmissions",
          status: "inactive",
        },
        {
          id: 2,
          title: "dashboard.leads",
          img: require("@/assets/images/dashboard/creator/brandme-leads.png"),
          description: "dashboard.descriptionLeads",
          href: "/leads",
          variant: "warning",
          alert: null,
          alert_text: null,
          status: "active",
        },
        {
          id: 3,
          title: "dashboard.shop",
          img: require("@/assets/images/dashboard/creator/brandme-shop.png"),
          description: "dashboard.descriptionShop",
          href: "https://shop.brandme.la/",
          variant: "info",
          alert: null,
          alert_text: null,
          status: "active",
        },
        {
          id: 4,
          title: "dashboard.talent",
          img: require("@/assets/images/dashboard/creator/brandme-talent.png"),
          description: "dashboard.descriptionTalent",
          href: "https://brandme.la/brandme-talent/",
          variant: "secondary",
          alert: null,
          alert_text: null,
          status: "active",
        },
        {
          id: 5,
          title: "dashboard.tools",
          img: require("@/assets/images/dashboard/creator/freetools.png"),
          description: "dashboard.descriptionFreetools",
          href: "https://brandme.la/free-tools/",
          variant: "success",
          alert: null,
          alert_text: null,
          status: "active",
        },
        {
          id: 6,
          title: "dashboard.mediaKit",
          img: require("@/assets/images/dashboard/creator/media-kit.png"),
          description: "dashboard.descriptionMediaKit",
          href: null,
          variant: "primary",
          alert: null,
          alert_text: null,
          status: "inactive",
        },
      ],
      homeBanner: {
        imageName: "bannerButtonImage.png",
        title: "dashboard.bannerTitle",
        textContent: "dashboard.bannerContent",
        buttonName: "dashboard.buttonName",
        link: "pages/account-setting/Social",
      },
      limit: 10,
      campaign: {},
      campaignView: false,
      downImg: require("@/assets/images/banner/banner_brand.png"),
      imgCreator: require("@/assets/images/banner/banner_creator.png"),
      utils,
      hRol: "",
      width: window.screen.width,
    };
  },
  created() {
    this.getGroup();

  },
  mounted() {
    this.getBanners();
  },
  methods: {
    getGroup() {
      const user = JSON.parse(localStorage.userData);
      this.groupRol = user.groups[0].name;
      this.hRol =
        this.groupRol === "creator" ? "height:250px" : this.width > 1400 || this.width < 1300 ? "height:350px" : "";
      this.campaignView = utils.hasPermission('view_campaign');
    },
    getTitle() {
      return this.$t("dashboard.titleLead");
    },
    getVariant(participation) {
      if (participation === null || participation.status === "approved_lead")
        return "success";
      return "warning";
    },
    getVariantBg(info) {
      if (info.variant) return info.variant;
      else return "primary";
    },
    triggerChat() {
      window.HubSpotConversations.widget.open();
    },
    getBanners() {
      service.getBanners().then((response) => {
        response.forEach((banner) => {
          const obj = { url_file: "", image_file: "", position: "" };
          obj.url_file = banner.url_file;
          obj.image_file = service.getCore + banner.image_file;
          obj.position = banner.position;
          this.banners.push(obj);
        });
      });
    },
  },
  props: {},
};
</script>

<style>
.container-metrics{
  margin-top: 22px;
  justify-content: center;
  font-weight: bold;
}
.metrics{
  font-weight: bold;
}
.without-border {
  border: none !important;
}
.icon-networks-connected {
  font-size: 15px;
  color: #fff !important;
  padding: 10px;
}
.icon-alert-profile{
  position:absolute;
  z-index: 2;
  top:19px;
  left:23px;
  font-size: 11px;
}
.without-border {
  border: none !important;
}
.hoverCard:hover {
  transform: scale(1.05);
  transition: all 500ms;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}
.header-card-creator {
  height: 188px;
}
.title-card {
  color: #0e131a;
}
.text-social {
  color: #0e131a;
  font-weight: bold;
  margin-block: 10px !important;
}
.text-black {
  color: #6e6b7b;
}
.btn-blue-brandme {
  background: rgb(31, 86, 255);
  background: radial-gradient(
    circle,
    rgba(31, 86, 255, 1) 0%,
    rgba(0, 63, 255, 1) 45%
  );
  border-color: rgb(31, 86, 255) !important;
}
.container-cards-principal {
  justify-content: space-between;
}
.container-card-creator {
  padding-left: 29px;
}
.container-button-proposal {
  align-items: center;
  max-height: 80px;
}
.button-proposal {
  align-items: center;
  background-color: #55a6c4 !important;
  border:none;
}
.link-my-work {
  cursor: pointer;
  font-size: 1.714rem;
  font-weight: 500;
  line-height: 1.2;
  color: #5e5873;
}
</style>