<template>
  <b-row>
    <b-col
      lg="6"
      md="6"
      sm="12"
      class="vx-col w-full lg:w-1/2 md:w-1/2 mb-base carousel-example"
    >
      <swiper :options="swiperOption">
        <swiper-slide v-for="(banner, index) in banners" :key="index">
          <a :href="banner.url_file" target="_blank">
            <img class="img-fluid" :src="banner.image_file" alt="banner" />
          </a>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </b-col>
    <b-col
      lg="6"
      md="6"
      sm="12"
      class="vx-col w-full lg:w-1/2 md:w-1/2 mb-base"
    >
      <auto-search :active="campaignView"></auto-search>
    </b-col>
   
      <b-col cols="12" class="mt-5">
        <b-card class="banner-leads">
          <feather-icon icon="CreditCardIcon" size="40" class="icon-leads"/>
          <h1 class="ml-2">{{ $t("dashboard.selectTool") }}</h1>
        </b-card>
      </b-col>

      
      <b-col
        cols="12"
        class="col-lg-4 col-md-6 col-sm-12"
        v-for="(cardInfo, index) in cardsBrand"
        v-bind:key="index"
      >
        <b-link
          :href="cardInfo.href"
          target="_blank"
          :class="cardInfo.status === 'inactive' ? 'link-disabled' : ''"
          :disabled="cardInfo.status !== 'active'"
        >
          <b-card
            v-if="cardInfo.img"
            class="hoverCard"
            header-class="header-card-creator"
            :img-src="cardInfo.img"
            :img-alt="cardInfo.title"
            img-top
          >
            <b-card-title class="mt-2">{{ $t(cardInfo.title) }}</b-card-title>
            <b-card-text class="text-black">
              {{ $t(cardInfo.description) }}
            </b-card-text>
          </b-card>
          <b-card
            v-else
            :header-bg-variant="getVariantBg(cardInfo)"
            header=" "
            header-class="header-card-creator"
          >
            <b-card-title class="mt-2">{{ cardInfo.title }}</b-card-title>
            <b-card-text class="black">
              {{ cardInfo.description }}
            </b-card-text>
          </b-card>
        </b-link>
      </b-col>
    
  </b-row>

</template>
  
<script>
import { BRow, BCol, VBToggle, BCard } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import AutoSearch from "@/views/search/search.vue";
import service from "@/services/dashboard";

export default {
  name: "DashboardBrand",
  components: {
    BRow,
    BCol,
    Swiper,
    SwiperSlide,
    AutoSearch,
    BCard,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
    swiper: directive,
  },
  data() {
    return {
      swiperOption: {
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      banners: [],
      cardsBrand: [
        {
          id: 1,
          title: "dashboard.influencerEngine",
          img: require("@/assets/images/dashboard/brand/Buscador.png"),
          description: "dashboard.influencerEngineText",
          href: null,
          variant: "primary",
          status: "inactive",
        },
        {
          id: 2,
          title: "dashboard.accountAnalyzer",
          img: require("@/assets/images/dashboard/brand/Analizador.png"),
          description: "dashboard.accountAnalyzerText",
          href: null,
          variant: "primary",
          status: "inactive",
        },
        {
          id: 3,
          title: "dashboard.campManager",
          img: require("@/assets/images/dashboard/brand/Gestionador.png"),
          description: "dashboard.campManagerText",
          href: null,
          variant: "primary",
          status: "inactive",
        },
        {
          id: 4,
          title: "dashboard.sponsorships",
          img: require("@/assets/images/dashboard/brand/Sponsorships.png"),
          description: "dashboard.sponsorshipsText",
          href: null,
          variant: "primary",
          status: "inactive",
        },
        {
          id: 5,
          title: "dashboard.lists",
          img: require("@/assets/images/dashboard/brand/Listas.png"),
          description: "dashboard.listsText",
          href: null,
          variant: "primary",
          status: "inactive",
        },
        {
          id: 6,
          title: "dashboard.personalizedServide",
          img: require("@/assets/images/dashboard/brand/Servicio personalizado.png"),
          description: "dashboard.personalizedServideText",
          href: null,
          variant: "primary",
          status: "inactive",
        },
      ],
    };
  },
  mounted() {
    this.getBanners();
  },
  methods: {
    getBanners() {
      service.getBanners().then((response) => {
        response.forEach((banner) => {
          const obj = { url_file: "", image_file: "", position: "" };
          obj.url_file = banner.url_file;
          obj.image_file = service.getCore + banner.image_file;
          obj.position = banner.position;
          this.banners.push(obj);
        });
      });
    },
    getVariantBg(info) {
      if (info.variant) return info.variant;
      else return "primary";
    },
  },
  props: {},
};
</script>
<style>
.banner-leads .card-body {
  display: flex;
  justify-content: flex-start;
}
.icon-leads {
  background-color: rgba(115, 103, 240, 0.12);
  color: #7367f0;
  padding: 5px;
  margin-right: 10px;
  border-radius: 10px;
  height: 40px;
  width: 40px;
  min-width: 40px;
} 
.hoverCard:hover {
  transform: scale(1.05);
  transition: all 500ms;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}
.header-card-creator {
  height: 188px;
}
.text-black {
  color: #6e6b7b;
}
</style>