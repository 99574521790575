<template>
  <b-row>
    <b-col>
    <b-card class="overflow-hidden container-card">
      <div :class="colorDiv"></div>
      <span :class="titleStyle">{{ title }}</span>
      <h2 :class="dateStyle">{{ dateCard }}</h2>
      <p :class="subtitleStyle">{{ subtitle }}</p>
    </b-card>
  </b-col>
  </b-row>
</template>
    
<script>
import { BCol, BRow, BCard, } from "bootstrap-vue";

export default {
  props: {
    title: {
      type: String,
      default: 'Active Orders'
    },
    subtitle: {
      type: String,
      default: '-- Listing Published'
    },
    colorDiv: {
      type: String,
      default: 'default-color-background'
    },
    titleStyle: {
      type: String,
      default: 'title-card'
    },
    subtitleStyle: {
      type: String,
      default: 'subtitle-card'
    },
    dateCard: {
      type: String,
      default: '--'
    },
    dateStyle: {
      type: String,
      default: ''
    },
  },
  name: "cardWork",
  components: {
    BCard,
    BCol,
    BRow,
  },
};
</script>
    
<style>
.default-color-background {
  background: linear-gradient(180deg, #50d0ad 0%, #29809a 100%);
  width: 10px;
  height: 220px;
  position: absolute;
  top: 0;
  left: 0px;
}
.card-pending-color-background {
  background: linear-gradient(to bottom, #fd0675, #8f2475);
}
.container-card {
  border: 0.0625rem solid rgba(222, 226, 230, 0.7);
  height: 140px;
}
.title-card {
  font-size: 0.7109375rem;
  color: #3e7ba0 !important;
  font-weight: bold;
  text-transform: uppercase;
}
.subtitle-card {
  color: #6c757d !important;
}
</style>