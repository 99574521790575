<template>
  <b-row class="match-height">
      <b-card
        img-src="@/assets/images/home/creador_header.jpg"
        img-alt="Profile Cover Photo"
        img-top
        class="card-profile"
        footer-class="without-border"
      >
        <div class="profile-image-wrapper">
          <div class="profile-image p-0">
            <b-avatar
              size="114"
              variant="light"
              :src="profile_image"
              href="#"
              @click="modalImage()"
            />
          </div>
        </div>
        <h3>
          {{
            `${this.profile.first_name.toUpperCase()} ${this.profile.last_name.toUpperCase()}`
          }}
        </h3>
        <h6 class="text-muted">
          {{ this.profile.email }}
        </h6>
        <h6 class="text-muted d-flex align-items-center justify-content-center">
          <country-flag
            class="mr-1"
            :country="this.profile.country"
            size="small"
            style="font-size: 10px"
          />
          {{ this.profile.country }}
        </h6>
        <hr class="mb-1" />
       
        <b-row class="container-button-social">
          <div class="text-social">
          <span class="col-6 ">
            {{ $t("dashboard.socialOverview") }}
          </span>
          </div>
          <div class="col-6">
            <b-button
              class="btn-blue-brandme"
              @click="$router.push('/pages/account-setting/Social')"
            >
              <span class="text-network">{{ $t("buttonAddNetwork") }}</span>
            </b-button>
          </div>
        </b-row>
        <div
            v-if="profile.networks.length > 0"
            class="
              d-flex
              justify-content-center
              align-items-center
              flex-wrap
            "
          >
             <b-media
              v-for="(item, index) in profile.networks"
              :key="index"
              no-body
            >
              <b-media-aside class="mr-1 mt-1">
                <b-avatar
                  :id="'tooltip-network-' + item.uuid"
                  target="_blank"
                  size="40"
                  v-if="item.connected"
                  variant=""
                  :icon="item.network"
                  :class="utils.getLocalNetworkName(item.network)"
                  :href="getLinkNetwork(item)"
                >
                  <b-img
                    :src="getSrcIconColor(item.network)"
                    width="20px"
                    v-if="
                      item.network !== 'twitter' &&
                      item.network !== 'facebook' &&
                      item.network !== 'twitch' &&
                      item.network !== 'linkedin' &&
                      item.network !== 'youtube' &&
                      item.network !== 'instagram'
                    "
                  ></b-img>
                </b-avatar>
                <b-avatar
                  :id="'tooltip-network-' + item.uuid"
                  v-else
                  size="30"
                  button
                  @click="redirectSocialSettings()"
                >
                  <i
                    :class="`${utils.getClassNetworkSpan(
                      item.network
                    )} bg-secondary icon-networks-connected`"
                  />
                  <div v-if="!item.connected" class="icon-alert-profile">
                    <b-icon
                      :id="`connect-${item.uuid}`"
                      icon="exclamation-circle-fill"
                      variant="warning"
                      @click="redirectSocialSettings()"
                    ></b-icon>
                  </div>
                </b-avatar>
              </b-media-aside>
              <b-tooltip :target="`connect-${item.uuid}`" placement="bottom">{{
                $t("connectNetwork")
              }}</b-tooltip>
              <b-tooltip
                :target="'tooltip-network-' + item.uuid"
                triggers="hover"
                placement="top"
              >
                @{{ item.username }}
              </b-tooltip>
            </b-media>
          </div>
        <b-row>
          <div class="col-6 container-metrics px-0">
            <b-col>
              <span class="title-metrics">{{ $t("dashboard.totalFollowers") }}</span>
            </b-col>
              <span> {{ utils.getFormat(totalFolllowers(profile)) }}</span>
          </div>
          <div class="col-6 container-metrics">
            <b-col>
              <span class="title-metrics">
                {{ $t("dashboard.ER") }}
              </span>
            </b-col>
              <span v-if="profile.er !== null"> {{ profile.er }}%</span>
              <span v-if="profile.er === null">{{ $t("dashboard.Null") }}</span>
          </div>
        </b-row>
      
      </b-card>
    <b-modal
      id="modal-center"
      centered
      :title="$t('dashboard.changeAvatar')"
      ref="change-avatar"
      :ok-title="$t('dashboard.save')"
      ok-only
      @ok="changeAvatar"
    >
      <b-form-file
        id="file-large"
        size="lg"
        v-model="avatar"
        :placeholder="$t('dashboard.chooseFile')"
        drop-placeholder="$t('dashboard.dropFile')"
        accept=".jpg, .png"
        :browse-text="$t('uploadFile')"
      ></b-form-file>
      
    </b-modal>
  </b-row>
</template>

<script>
import {
  BFormFile,
  BModal,  
  BIcon,
  BRow,
  BCol,
  BCard,
  BAvatar,
  BMedia,
  BMediaAside,
  BTooltip,
  BButton,
  BImg,
} from "bootstrap-vue";
import others from "@/services/others";
import utils from "@/libs/utils";
import profile_services from '@/services/settings'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import abilities from '@/libs/acl/abilities'
import service from "@/services/dashboard";

export default {
  name: "CardCreator",
  components: {
    BFormFile,
    BModal, 
    BIcon,
    BRow,
    BCol,
    BCard,
    BAvatar,
    BMedia,
    BMediaAside,
    BTooltip,
    BButton,
    BImg,
  },
  data() {
    return {
      avatar: null,
      profile_image: null,
      netIconsColor: {
        tiktok: require("@/assets/images/icons/tiktok2.svg"),
        spotify: require("@/assets/images/icons/spotify2.svg"),
        pinterest: require("@/assets/images/icons/pinterest2.svg"),
        blog: require("@/assets/images/icons/blog2.svg"),
        snapchat: require("@/assets/images/icons/snapchat2.svg"),
      },
      profile: {
        first_name: "",
        last_name: "",
        profile_image: "",
        country: "",
        networks:[],
      },
      campaign: {},
      campaignView: false,
      downImg: require("@/assets/images/banner/banner_brand.png"),
      imgCreator: require("@/assets/images/banner/banner_creator.png"),
      utils,
      hRol: "",
      width: window.screen.width,
    };
  },
  created() {
    this.getGroup();
  },
  mounted() {
    this.getProfile();
  },
  methods: {
    getGroup() {
      const user = JSON.parse(localStorage.userData);
      this.groupRol = user.groups[0].name;
      this.hRol =
        this.groupRol === "creator" ? "height:250px" : this.width > 1400 || this.width < 1300 ? "height:350px" : "";
      this.campaignView = utils.hasPermission('view_campaign');
    },
    getProfile() {
      const user = JSON.parse(localStorage.userData);
      others.getProfile().then((response) => {
        this.profile = response.user;
        this.profile_image = this.getImgProfile(this.profile.profile_image);
        utils.setuserData(response, user.ability);
      });
    },
    getImgProfile(image_url) {
      if (image_url !== null && image_url !== "")
        return service.getCore + image_url;
      const img = require(`@/assets/images/avatars/${
        Math.floor(Math.random() * 4) + 1
      }.png`);
      return img;
    },
    modalImage() {
      this.avatar = null;
      this.$refs["change-avatar"].toggle("#toggle-btn");
    },
    changeAvatar() {
      
      if (this.avatar) {
        profile_services.changeAvatar(this.avatar)
          .then(result => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('dashboard.saved'),
                variant: 'success',
                icon: null,
                text: this.$t('dashboard.saved_avatar'),
              }
            })


            const readAbility = abilities.getAbilitie(result.user)
            utils.setuserData(result, readAbility);
            this.profile_image = this.getImgProfile(result.user.profile_image);
          }).catch(() => {
          });
      }
    },
    getLinkNetwork(profile) {
      if (profile.network === 'facebook' && profile.link) return profile.link
      return utils.getURL(profile.network, profile.username, profile.is_channel)
    },
    getSrcIconColor(network) {
      const index_network = Object.keys(this.netIconsColor).indexOf(network)

      if (index_network === -1) return

      return Object.values(this.netIconsColor)[index_network]
    },
    redirectSocialSettings() {
      this.$router.push('/pages/account-setting/Social')
    },
    totalFolllowers(profile) {
      let sum = 0

      profile.networks.forEach(item => { 
        sum += item.followers
      });
      return sum

    }
  },
};
</script>
<style>
.icon-networks-connected {
  font-size: 15px;
  color: #fff !important;
  padding: 10px;
}
.icon-alert-profile{
  position:absolute;
  z-index: 2;
  top:19px;
  left:23px;
  font-size: 11px;
}
.container-metrics {
  margin-top: 22px;
  text-align: center; 
  
}
.title-metrics {
  text-transform: uppercase !important;
  color: #6c757d;
  font-weight: 400 !important;
}
.metrics {
  font-weight: 500 !important;
  color: #212529 !important;
}
.container-button-social {
  justify-content: space-between;
}
.text-network {
  font-size: small;
}
.btn-blue-brandme {
  width: 130px;
  padding-left: 4px;
  padding-right: 4px;
  background: #0096fe !important;
  border: none;
}
</style>