<template>
  <b-row>
    <b-tooltip target="coming-soon" :title="$t('dashboard.comingSoon')"></b-tooltip>
    <b-col class="col-12 col-md-6">
       <card-Work id="coming-soon"></card-Work>
    </b-col>
    
    <b-col class="col-12 col-md-6">
      <card-Work 
        id="coming-soon"
        :title="'Pending payout'" 
        :colorDiv="'card-pending-color-background'"
        :titleStyle="'text-pending'"
        :dateCard="'$0'"
        :subtitle="'$0 Cashed Out'"
        >
      </card-Work>
    </b-col>
  </b-row>
</template>
  
<script>
import { BCol, BRow, BTooltip, } from "bootstrap-vue";
import cardWork from '@/views/pages/creator/cardWork.vue';

export default {
  name: "desk",
  components: {
    BTooltip,
    cardWork,
    BCol,
    BRow,
  },
};
</script>
  
<style>

.card-pending-color-background {
    background: linear-gradient(to bottom, #fd0675, #8f2475);
    width: 10px;
    height: 220px;
    position: absolute;
    top: 0;
    left: 0px;
}
.container-card {
    border: 0.0625rem solid rgba(222,226,230,.7);
    height: 140px;
}
.text-orders {
    font-size: .7109375rem;
    color: #3e7ba0;
    font-weight: bold;
    text-transform: uppercase;
}
.text-view {
    display: block;
    font-weight: bold !important;
    font-size: .75rem;
    color: #55a6c4;
}
.text-pending {
    font-size: .7109375rem;
    color: #8f2475;
    font-weight: bold;
    text-transform: uppercase;
}
.price {
    color: #55a6c4;
}
</style>