<template>
  <b-card>
    <b-row class="match-height">
      <b-col
        md="12"
      >
        <h2>{{ $t('dashboard.analyzeInfluencer') }}</h2>
      </b-col>
      <b-col
        md="12"
        class="mt-1"
      >
      <b-tabs :active-nav-item-class="`colorTab${networkTabColor}`" active-tab-class="border-0" v-model="active_tab">
        <b-tab
          v-on:click="selectNetwork('instagram')"
        >
          <template #title>
            <feather-icon icon="InstagramIcon" size="20"/>
            Instagram
          </template>
        </b-tab>
        <b-tab
          v-on:click="selectNetwork('tiktok')"
        >
          <template #title>
            <span :class="`icomoon-tiktok tiktokIconAnalize icon-tiktok`" v-if="active_tab === 1"></span>
            <span :class="`icomoon-tiktok tiktokIconAnalize`" v-else></span>

            TikTok
          </template>          
        </b-tab>
        <b-tab
          v-on:click="selectNetwork('twitter')"
        >
          <template #title>
            <feather-icon icon="TwitterIcon" size="20"/>
            Twitter
          </template>
        </b-tab>
        <b-tab
          v-on:click="selectNetwork('youtube')"
        >
          <template #title>
            <feather-icon icon="YoutubeIcon" size="20"/>
            Youtube
          </template>
        </b-tab>
        <b-tab
          v-on:click="selectNetwork('twitch')"
        >
          <template #title>
            <feather-icon icon="TwitchIcon" size="20"/>
            Twitch
          </template>        
        </b-tab>
        </b-tabs>
      </b-col>
      <div
        class="mb-1 block ml-1 d-flex w-100"
      >
        <b-row class="w-100">
          <b-col cols="8" class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12">
            <vue-autosuggest
              v-model="query"
              :suggestions="filteredOptions"
              :input-props="inputProps"
              :limit="10"
              @click="resetQuery"
              :get-suggestion-value="onSelected"
              ref="inputSearch"
            >
              <template slot="before-input">
                {{$t('maxviews') }} {{max_view}}
              </template>
            </vue-autosuggest>
          </b-col>
          <b-col cols="4" class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
            <div id="toolTipAnalize" class="d-flex flex-column justify-content-center mt-1 mt-lg-2">
            <b-button class="buttonAnalyze text-white"
              variant="buttonAnalyze"
              @click="getResults"
              :disabled="finish || query.length === 0"
              ref="loadableButton"
              >
              {{$t('dashboard.analyze') }}
              </b-button>
            </div>
            <b-tooltip target="toolTipAnalize" class="p-1" v-if="finish">
              {{$t('header.menu.disabledTooltip')}} 
              <b-button variant="primary" class="mt-1 p-0"><a href="https://brandme.la/solicitar-propuesta/" target="_blank" class="text-white block aRedirect">{{$t('header.menu.disabledButton')}} </a> </b-button>
            </b-tooltip>
          </b-col>
        </b-row>
      </div>
      <b-col cols="12">
        <h6 v-if="filteredOptions.length !== 0">{{$t('dashboard.top')}}: </h6>
        <vuescroll :ops="ops">
          <ul class="chat-users-list chat-list media-list cursor-pointer">
            <chat-contact
              v-for="(item, x ) in filteredOptions"
              :key="x"
              :user="item"
              tag="li"
              is-chat-contact
              @click="onSelected(item)"    
              :class="item.class"
            />
          </ul>
        </vuescroll>
        <div class="container-loader loader-table"  v-if="loader">
          <b-spinner class="spinner-table-searcher"></b-spinner>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import {
  BRow, BCol, BCard, BButton, BTab, BTabs, BTooltip, BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import service from '@/services/search'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ChatContact from '@/views/apps/chat/ChatContact.vue'
import vuescroll from 'vuescroll'
import utils from '@/libs/utils'

export default {
  components: {
    BSpinner,
    BRow,
    BCol,
    BCard,
    VueAutosuggest,
    BButton,
    BTab,
    BTabs,
    ChatContact,
    vuescroll,
    BTooltip
  },
  directives: {
    Ripple
  },
  props: {
    active:{
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      datasuggest: [],
      query: '',
      filteredOptions: [],
      topYT: [
        {
          avatar_url: "https://yt3.ggpht.com/ytc/AAUvwnga3eXKkQgGU-3j1_jccZ0K9m6MbjepV0ksd7eBEw=s900-c-k-c0x00ffffff-no-rj",
          channel_id: "UC-lHJZR3Gqxm24_Vd_AJ5Yw",
          followers_count: 110532945,
          title: "PewDiePie",
          is_private: false,
          is_verified: true,
          link: "youtube/pewdiepie-UC-lHJZR3Gqxm24_Vd_AJ5Yw/",
          type: "youtube",
          username: "PewDiePie",
        },
        {
          avatar_url: "https://yt3.ggpht.com/ytc/AAUvwnicrG9RNUfo_lxIwy179qaAChmgaWrOs6Y4_r7CKg=s900-c-k-c0x00ffffff-no-rj",
          channel_id: "UC295-Dw_tDNtZXFeAPAW6Aw",
          followers_count: 72355996,
          title: "5-Minute Crafts",
          is_private: false,
          is_verified: true,
          link: "youtube/5minute_crafts-UC295-Dw_tDNtZXFeAPAW6Aw/",
          type: "youtube",
          username: "5-Minute Crafts"
        },
        {
          avatar_url: "https://yt3.ggpht.com/ytc/AAUvwnh6xcaO5CpkIoi-4Vg7Ni9rTlre_twXi7_Bmii-5g=s900-c-k-c0x00ffffff-no-rj",
          channel_id: "UCIwFjwMjI0y7PDBVEO9-bkQ",
          followers_count: 63444706,
          title: "Justin Bieber",
          is_private: false,
          is_verified: true,
          link: "youtube/justin_bieber-UCIwFjwMjI0y7PDBVEO9-bkQ/",
          type: "youtube",
          username: "Justin Bieber"
        }
      ],
      topIG: [
        {
          title: "BILLIE EILISH",
          avatar_url: require("@/assets/images/dashboard/search/ig/billieelish.jpg"),
          followers_count: 85756094,
          is_private: false,
          is_verified: true,
          username: "billieeilish",
          user_id: "28527810",
          type: "instagram",
          link: "instagram/billieeilish/"
        },
        {
          title: "Cristiano Ronaldo",
          avatar_url: require("@/assets/images/dashboard/search/ig/cristiano.ronaldo.jpg"),
          followers_count: 290721123,
          is_private: false,
          is_verified: true,
          username: "cristiano",
          user_id: "173560420",
          type: "instagram",
          link: "instagram/cristiano/"
        },
        {
          title: "Ariana Grande",
          avatar_url: require("@/assets/images/dashboard/search/ig/arianagrande.jpg"),
          followers_count: 237857082,
          is_private: false,
          is_verified: true,
          username: "arianagrande",
          user_id: "7719696",
          type: "instagram",
          link: "instagram/arianagrande/"
        }
      ],
      topTT: [
        {
          avatar_url: require('@/assets/images/dashboard/search/tt/willsmith.jpeg'),
          followers_count: 53800000,
          title: "Will Smith",
          is_private: false,
          is_verified: true,
          link: "tiktok/willsmith/",
          type: "tiktok",
          user_id: "6727327145951183878",
          username: "willsmith"
        },
        {
          avatar_url: require('@/assets/images/dashboard/search/tt/jasonderulo.jpeg'),
          followers_count: 46000000,
          title: "Jason Derulo",
          is_private: false,
          is_verified: true,
          link: "tiktok/jasonderulo/",
          type: "tiktok",
          user_id: "21609287",
          username: "jasonderulo"
        },
        {
          avatar_url: require('@/assets/images/dashboard/search/tt/kimberly.loaiza.jpeg'),
          followers_count: 43700000,
          title: "Kimberly Loaiza ✨",
          is_private: false,
          is_verified: true,
          link: "tiktok/kimberly.loaiza/",
          type: "tiktok",
          user_id: "6596805238354558982",
          username: "kimberly.loaiza"
        }
      ],
      topTW: [
        {
          title: 'Ninja',
          avatar_url: 'https://static-cdn.jtvnw.net/jtv_user_pictures/7f8e182b-cf35-444d-a349-ce88f894d0ca-profile_image-50x50.png',
          followers_count: 16758907,
          is_private: false,
          is_verified: false,
          username: 'ninja',
          user_id: '19571641',
          type: 'twitch',
          link: 'twitch/ninja/'
        },
        {
          title: 'Tfue',
          avatar_url: 'https://static-cdn.jtvnw.net/jtv_user_pictures/82b63a01-628f-4c81-9b05-dd3a5011fdda-profile_image-50x50.png',
          followers_count: 10479526,
          is_private: false,
          is_verified: false,
          username: 'tfue',
          user_id: '60056333',
          type: 'twitch',
          link: 'twitch/tfue/'
        },
        {
          title: 'Rubius',
          avatar_url: 'https://static-cdn.jtvnw.net/jtv_user_pictures/a2592e98-5ba6-4c9a-9d9e-cf036d6f64c2-profile_image-50x50.jpg',
          followers_count: 9415370,
          is_private: false,
          is_verified: false,
          username: 'rubius',
          user_id: '39276140',
          type: 'twitch',
          link: 'twitch/rubius/'
        }
      ],
      topTR: [
        {
          title: 'Yuya',
          avatar_url: 'https://pbs.twimg.com/profile_images/1239990263776333825/J9LCGT4w_400x400.jpg',
          followers_count: 11062546,
          is_private: false,
          is_verified: false,
          username: 'yuyacst',
          user_id: '212746249',
          type: 'twitter',
          link: 'twitter/yuyacst/'
        },
        {
          title: 'Paulina Rubio',
          avatar_url: 'https://pbs.twimg.com/profile_images/1364309833533558786/W6Xy8K_I_400x400.jpg',
          followers_count: 11001180,
          is_private: false,
          is_verified: false,
          username: 'paulinarubio',
          user_id: '23605048',
          type: 'twitter',
          link: 'twitter/paulinarubio/'
        },
        {
          title: 'Eugenio Derbez',
          avatar_url: 'https://pbs.twimg.com/profile_images/1158581892142133249/E1h6tp42_400x400.jpg',
          followers_count: 11213051,
          is_private: false,
          is_verified: false,
          username: 'eugenioderbez',
          user_id: '46527223',
          type: 'twitter',
          link: 'twitter/eugenioderbez/'
        }
      ],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: ''
      },
      networkSearch: 'instagram',
      networkName: 'Instagram',
      limit: 9,

      moreInfo: {
        avatar_url: require('@/assets/images/icons/compass.svg'),
        type: 'null',
        title:  this.$t('dashboard.info'),
        username: '',
        is_verified: false,
        class: 'moreInfo'
      },
      finish: true,
      loader: false,
      text: this.$t('dashboard.analyze'),
      textResults: this.$t('dashboard.top'),
      ops: {
        vuescroll: {},
        scrollPanel: {
          maxHeight: 130
        },
        rail: {},
        bar: {
          background: '#0077e9'
        }
      },
      max_view: 0,
      networkTabColor: 'instagram',
      active_tab: 0
    }
  },
  created () {
    this.inputProps.placeholder = `${this.$t('dashboard.nameuser')} ${this.networkName}`
    this.filteredOptions = this.topIG;
    this.finish = !this.checkAnalyzer();
    const user = JSON.parse(localStorage.userData);
    this.max_view = user.max_views

  },
  methods: {
    network_name(network) {
      
      if (network === 'instagram')
        return 'Instagram'
      if (network === 'tiktok')
        return 'TikTok'
      if (network === 'youtube')
        return 'YouTube'
      if (network === 'twitch')
        return 'Twitch'
      if (network === 'twitter')
        return 'Twitter'
      return network
    },
    selectNetwork (red) {
      this.networkSearch = red
      this.networkName = this.network_name(red)
      this.inputProps.placeholder = `${this.$t('dashboard.nameuser')} ${this.networkName}`
      this.filteredOptions = red === 'youtube' ? this.topYT : red === 'tiktok' ? this.topTT : red === 'twitch' ? this.topTW : red === 'twitter' ? this.topTR : this.topIG
      this.ops.scrollPanel.maxHeight = 130
      this.resetQuery()
    },
    resetQuery () {
      this.query = ''
      this.inputProps.class = 'form-control'
    },
    getResults () {
     
      if (this.query !== '') {
        this.finish = true
        this.loader = true
        this.inputProps.class = 'form-control locked-input'
        this.text = this.$t('dashboard.analyzing')
        service.analize(utils.trimText(this.query), this.networkSearch)
          .then(res => {
            this.datasuggest = res.list
            const filteredData = this.datasuggest.slice(0, this.limit)
            const color = filteredData.length === 0 ? 'warning' : 'success'
            const texto = filteredData.length === 0 ? this.$t('dashboard.noneResults') : this.$t('dashboard.lookResults')
            filteredData.push(this.moreInfo)
            this.filteredOptions = filteredData
            this.text =  this.$t('dashboard.analyze'),
            this.textResults = this.$t('dashboard.results')
            this.finish = false
            this.loader = false
            this.inputProps.class = 'form-control'
            this.ops.scrollPanel.maxHeight = 130
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('dashboard.finished') ,
                icon: 'AlertCircleIcon',
                variant: color,
                text: texto
              }
            })
          })
          .catch(() => {
            this.text =  this.$t('dashboard.analyze'),
            this.textResults = this.$t('dashboard.results')
            this.finish = false
            this.inputProps.class = 'form-control'
          })
      }
    },
    onSelected (item) {
      if (item.type === 'null') {
        this.$router.push(`/search`)
      } else {
        this.query = item.full_name
        this.ops.scrollPanel.maxHeight = 130

        if (utils.hasPermission('view_profile')) {
          const username = this.networkSearch === 'youtube' ? item.channel_id : item.username
          this.filteredOptions = []
          this.$router.push(`/perfil/${this.networkSearch}/${username}`)
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('dashboard.premium'),
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: this.$t('dashboard.contact')
            }
          })
        }
      }
    },
    searchInfluencer () {
      this.getResults()
      this.filteredOptions = []

    },
    checkAnalyzer() {
      return utils.hasPermission('view_analyzer');
    },
  },
  watch: {
    '$i18n.locale'() {
      this.inputProps.placeholder = `${this.$t('dashboard.nameuser')} ${this.networkName}`
    },
    active_tab(val) {
      if (val === 0) {
        this.networkTabColor = 'instagram'
      } else if (val === 1) {
        this.networkTabColor = 'tiktok'
      } else if (val === 2) {
        this.networkTabColor = 'twitter'
      } else if (val === 3) {
        this.networkTabColor = 'youtube'
      } else if (val === 4) {
        this.networkTabColor = 'twitch'
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>

<style scoped>
  .radio label {
    position: relative;
    cursor: pointer;
    float: left;
    line-height: 40px;
    margin-bottom:0 !important;
  }
  .radio input {
    position: absolute;
    opacity: 0;
  }
  .radio input:checked ~ .radio-control {
    border-bottom: 1px solid red;
    color: red;
    margin-bottom:4px;
  }

  .instagramBoton {
    background: red;
  }
</style>

<style>
  .container-loader {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(255,255,255, 0.5);
    z-index: 1000;
    right: 5px;
  }
  .loader-table {
    top: 0px;
    z-index: 10;
    display: flex;
    justify-content: center;
  }
  .spinner-table-searcher {
    margin-top: 3em;
    height: 3em;
    width: 3em;
    color: #acacac;
  }
  .colorTabinstagram {
    color: #ed4960 !important;
    border-bottom: 2px solid #ed4960 !important;
  }
  .colorTabyoutube {
    color: #ff051d !important;
    border-bottom: 2px solid #ff051d !important;
  }
  .colorTabtiktok {
    color: #ff0653 !important;
    border-bottom: 2px solid #ff0653 !important;
  }
  .colorTabtwitch {
    color: #9449fa !important;
    border-bottom: 2px solid #9449fa !important;
  }
  .colorTabtwitter {
    color: #009cec !important;
    border-bottom: 2px solid #009cec !important;
  }
  .nav-tabs .nav-link:after {
    background: none !important;
  }
  .tiktokIconAnalize {
    font-size: 1.2em;
    margin-right: 0.4em;
    color: #444 !important;
  }
  .moreInfo h5 {
    color: #2577d4;
  }
  .moreInfo h5:hover {
    color: #0067cc;
  }
  .buttonAnalyze {
    background-color:#0096fe !important;
    border: 0 !important;
  }
  .buttonAnalyze:hover{
    background-color:rgb(77, 182, 249) !important;     
    box-shadow: rgba(77, 182, 249, 0.3) 0px 7px 29px 0px !important;  
  }
  .buttonAnalyze:disabled {
    background-color: #0094fe86 !important;
  }
  .buttonAnalyze:disabled:hover {
    box-shadow: none !important;
  }
  .icon-tiktok{
    color: #ff0653 !important;
  }
</style>
