<template>
  <section id="pricing-plan">
    <div class="pricing-free-trial home-banner">
      <b-row>
        <b-col lg="10" offset-lg="3" class="mx-auto">
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-3">
              <h3 class="text-primary">
                {{ $t(title) }}
              </h3>
              <h5 v-html="$t(textContent)">
              </h5>
              <b-button
                variant="primary"
                class="mt-2 mt-lg-3"
                @click="$router.push({ path: link })"
              >
                {{ $t(buttonName) }}
              </b-button>
            </div>
            <b-img fluid :src="banner" class="img" alt="svg img" v-if="imageName"/>
          </div>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import { BImg, BButton, BCol, BRow } from "bootstrap-vue";

export default {
  title: 'HomeBanner',
  components: {
    BImg,
    BCol,
    BRow,
    BButton,
  },
  data() {
    return {
      banner: require(`@/assets/images/banner/${this.imageName}`),
    }
  },
  props: {
    imageName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    textContent: {
      type: String,
      required: true,
    },
    buttonName: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
.home-banner {
  height: 20.71rem !important;
  background-color: rgba(115, 103, 240, 0.12) !important ;
}
.img {
  top: -8rem !important;
}

@media (max-width: 770px) {
  .img {
    top: -0rem !important;
  }
  .home-banner {
    height: 40rem !important;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
</style>
